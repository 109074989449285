import { ErrorType, IErrorState, IRequestState, RequestState } from "../applicationStates";
import { SiufQuestionStructure, SiufQuestionType, SiufState, IReviewSiufEntity, IReviewSiufEntity_Default } from "./serverResponseContracts";
import { ID_NOT_AVAILABLE } from "./constants";

//
// App models that are used to transform to/from server contract models
//

export enum SiufTypeSelection {
    singleQuestion = 0,
    multiQuestion = 1
}

export enum SiufLifeTimeOption {
    _30Days = 30,
    _90Days = 90,
    _180Days = 180,
    _365Days = 365,
    _730Days = 730
}

export enum SiufTargetRingSelection {
    custom = 0,
    internalMicrosoftAndInsider = 1,
    internalMicrosoft = 2,
    insider = 3,
    retailAll = 4
}

export enum SiufTargetRingChannelSelection {
    custom = 0,
    internalMicrosoftAndInsider = 1,
    internalMicrosoft = 2,
    insider = 3,
    retailAll = 4,
    internalMicrosoftChannel = 5,
    internalMicrosoftAndInsiderChannel = 6,
    insiderChannel = 7,
    internalPreviewChannel = 8,
    previewChannel = 9
}

export enum SiufAdvancedProperty {
    frequencyRule = 0,
    reaskRule,
    reaskAfterUpdate,
    postLogonQuietPeriod,
    noAutoDeactivate,
    doNotAskSiufAgainIfSeenPreviousVersion,
    askEmail,
    useFeedbackHubToToast = 8,
    reaskAfterCoolOff,
}

export enum SubmitOperation {
    none,
    createSiuf,
    loadSiuf,
    saveSiuf,
    validateSiuf,
    submitSiufForReview,
    withdrawSiufFromReview,
    terminateSiuf,
    reactivateSiuf,
    sideloadSiuf
}

export enum SiufSortProperty {
    id = 0,
    name,
    owner,
    triggerType,
    targeting,
    version,
    state
}

export enum SiufReviewState {
    notReviewed = 0,
    inProgress = 1,
    approved = 2,
    rejected = 3,
    terminated = 4,
    error = 5
}

export interface ISiufReviewInfo {
    siufId: number;
    siufReviewEntity: IReviewSiufEntity;
    reviewState: SiufReviewState;
    errorMessage: string;
}

export const ISiufReviewInfo_Default: ISiufReviewInfo = {
    siufId: ID_NOT_AVAILABLE,
    siufReviewEntity: IReviewSiufEntity_Default,
    reviewState: SiufReviewState.notReviewed,
    errorMessage: ""
};

export interface ISiufAdvancedPropertySection {
    frequencyRuleEnabled: boolean;
    reaskRuleEnabled: boolean;
    reaskAfterUpdateEnabled: boolean;
    postLogonQuietPeriodEnabled: boolean;
    noAutoDeactivateEnabled: boolean;
    DoNotAskSiufAgainIfSeenPreviousVersionEnabled: boolean;
    askEmailEnabled: boolean;
    useFeedbackHubToToast: boolean;
    reaskAfterCoolOffEnabled: boolean;
}

export const ISiufAdvancedPropertySection_Default: ISiufAdvancedPropertySection = {
    frequencyRuleEnabled: false,
    reaskRuleEnabled: false,
    reaskAfterUpdateEnabled: false,
    postLogonQuietPeriodEnabled: false,
    noAutoDeactivateEnabled: false,
    DoNotAskSiufAgainIfSeenPreviousVersionEnabled: false,
    askEmailEnabled: false,
    useFeedbackHubToToast: false,
    reaskAfterCoolOffEnabled: false
};

export interface ISiufReviewSearchProperty {
    siufName: string;
    state: SiufState[];
    ownerAlias: string;
    siufId: number;
    scenarioId: string;
    questionType: SiufQuestionType[];
    siufStructure: SiufQuestionStructure[];
}

export const ISiufReviewSearchProperty_Default: ISiufReviewSearchProperty = {
    siufName: "",
    state: [SiufState.Submitted],
    ownerAlias: "",
    siufId: undefined,
    scenarioId: "",
    questionType: [],
    siufStructure: []
};

export interface ISiufReviewSortProperty {
    property: SiufSortProperty;
    isAscending: boolean;
}

export const ISiufReviewSortProperty_Default: ISiufReviewSortProperty = {
    property: SiufSortProperty.id,
    isAscending: false,
};

export interface ISubmitState<T> extends IRequestState, IErrorState {
    response?: T;
    operation: SubmitOperation;
}

export const ISubmitState_Default: ISubmitState<any> = {
    requestState: RequestState.notStarted,
    operation: SubmitOperation.none,
    errorType: ErrorType.none,
    errorMessage: ""
};