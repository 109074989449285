import { createSelector } from "reselect";
import { IApplicationRootReducerState } from "../../configureStore";
import { ENABLE_AUTO_INTERACTIVE_TARGETING, SiufCREException, SiufQuestionType, SiufTriggeringType } from "../../contracts/models";

const getSiufAppState = (state: IApplicationRootReducerState) => {
    return state.siufDomain.appState;
};

const getSiufUiState = (state: IApplicationRootReducerState) => {
    return state.siufDomain.uiState;
};

const getSiufResourceProvidersState = (state: IApplicationRootReducerState) => {
    return state.resourceProviders;
};

export const getNewSiufModel = createSelector(
    [getSiufAppState],
    (appState) => {
        return  appState.authoring.newSiuf.newSiufModel;
    }
);

export const getNewSiufModelSubmitState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.newSiuf.submitState;
    }
);

export const getExistingSiufDirtyState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.isDirty;
    }
);

export const getExistingSiufModelSubmitState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.submitState;
    }
);

export const getSiufValidationSubmitState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.validationSubmitState;
    }
);

export const getSiufSideloadSubmitState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.sideloadSubmitState;
    }
);

export const getSiufXmlDownloadSubmitState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.downloadXmlSubmitState;
    }
);

export const getSiufTypeSelection = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.siufTypeSelection;
    }
);

export const getSiufPreviewOptionSelection = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.currentSelectedPreviewOption;
    }
);

export const getSiufEntity = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.siufEntity;
    }
);

export const getSiufValidationResult = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.validationResult;
    }
);

export const getStateMachineRootState = createSelector(
    [getSiufEntity],
    (siuf) => {
        return siuf ? siuf.StateModel.StateMachineModel : null;
    }
);

export const getStateMachineMaxDurationMs = createSelector(
    [getSiufEntity],
    (siuf) => {
        return siuf ? siuf.StateModel.MaxDurationMs : 0;
    }
);

export const getSiufId = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.SiufId : -1
);

export const getSiufName = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.SiufName : null
);

export const getSiufJustifications = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.Justifications : []
);

export const getSiufOwner = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.Owner : null
);

export const getSiufPayload = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.Payload : null
);

export const getSiufTargetingEntity = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.Targeting : null
);

export const getSiufLifeTimeInDays = createSelector(
    [getSiufEntity],
    (siuf) => siuf ? siuf.LifeSpanInDays : 0
);

export const getSiufQuestions = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.Questions : []
);

export const getSiufPages = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.Pages : []
);

export const getSiufToastText = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.ToastText : ""
);

export const getSiufQuestionsSorted = createSelector(
    [getSiufQuestions],
    (questions) => {
        if (!questions) {
            return [];
        }

        let result = questions.sort((a, b) => {
            return a.QuestionIndex > b.QuestionIndex ? 1 : -1;
        });

        return result;
    }
);

export const getSiufPagesSorted = createSelector(
    [getSiufPages],
    (pages) => {
        if (!pages) {
            return [];
        }

        let result = pages.sort((a, b) => {
            return a.PageIndex > b.PageIndex ? 1 : -1;
        });
        result.forEach((page) => {
            page?.Questions?.sort((a,b) => {
                return a.QuestionIndex > b.QuestionIndex ? 1 : -1;
            })
        });
        return result;
    }
);

export const getSiufTriggerTypeSelection = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.TriggeringType : SiufTriggeringType.URI
);

export const getSiufQuestionsTitle = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.Title : null
);

export const getSiufIgnoreTitleOnToast = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.IgnoreTitleOnToast : false
);

export const getSiufActiveEditPage = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.ActiveEditPage : 0
);

export const getSiufActivePreviewPage = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.ActivePreviewPage : 0
);

export const getSideLoadEnabledState = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? (siufPayload.TriggeringType === SiufTriggeringType.StateMachine) : false
);

export const getExistingSiufMultiQuestionState = createSelector(
    [getSiufPayload],
    (siufPayload) => {
        let questions = siufPayload.Pages?.flatMap(page => page.Questions);
        return questions?.length > 1;
    }
);

export const getExportExtendedPayloadState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.authoring.currentSiuf.exportExtendedPayload;
    }
);

export const getSiufPreviousQuestionId = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.CREQuestionId : ""
);

export const getSiufCoolOffPeriod = createSelector(
    [getSiufPayload],
    (siufPayload) => siufPayload ? siufPayload.CoolOffPeriodInDays : null
);

export const getIsInsightfulSurvey = createSelector(
    [getSiufPayload],
    (siufPayload) => (siufPayload?.Pages[0]?.Questions[0]?.QuestionType === SiufQuestionType.Likert || 
        (siufPayload?.Pages?.length > 1 && ENABLE_AUTO_INTERACTIVE_TARGETING))
);

export const getSiufAdvancedProperties = createSelector(
    [getSiufPayload],
    (siufPayload) => {
        if (!siufPayload) {
            return null;
        }
        return {
            frequencyRuleEnabled: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.Frequency) !== -1,
            reaskRuleEnabled: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.Reask) !== -1,
            reaskAfterUpdateEnabled: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.ReaskAfterUpdate) !== -1,
            postLogonQuietPeriodEnabled: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.PostLogonQuietPeriod) !== -1,
            noAutoDeactivateEnabled: siufPayload.NoAutoDeactivate,
            DoNotAskSiufAgainIfSeenPreviousVersionEnabled: siufPayload.DoNotAskSiufAgainIfSeenPreviousVersion,
            askEmailEnabled: siufPayload.AskUserForEmail,
            useFeedbackHubToToast: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.UseFeedbackHubToToast) !== -1,
            reaskAfterCoolOffEnabled: siufPayload.CREExceptions && siufPayload.CREExceptions.findIndex(c => c.CREExceptionId === SiufCREException.ReaskAfterCoolOff) !== -1
        };
    }
);

export const getSiuAuthoringUiState = createSelector(
    [getSiufUiState],
    (uiState) => {
        return uiState.authoring;
    }
);

export const getSiufBasicsUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.siufBasics;
    }
);

export const getSiufQuestionDetailsUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.questionDetails;
    }
);

export const getSiufPreviewUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.preview;
    }
);

export const getSiufTriggerUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.trigger;
    }
);

export const getSiufTargetingUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.targeting;
    }
);

export const getSiufSettingsUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.settings;
    }
);

export const getSiufValidationUiState = createSelector(
    [getSiuAuthoringUiState],
    (uiState) => {
        return uiState.validation;
    }
);