import * as React from 'react';
import { Form, Header, Message } from 'semantic-ui-react';
import { ErrorType, RequestState } from '../../../contracts';
import { CreationType, INewSiufModel, ISiufEntity, ISubmitState, SiufTriggeringType, INewSiufModel_Default } from '../../../contracts/models';
import Navigation from '../../../navigation/navigationActions';

type Props = {
    newSiufModel: INewSiufModel;
    submitState: ISubmitState<ISiufEntity>;
    defaultOwnerUpn: string;
    onUpdateNewSiufProperties: (newSiufModel: INewSiufModel) => void;
    onCreateSiuf: (newSiufModel: INewSiufModel) => void;
};

export default class SiufCreation extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.props.onUpdateNewSiufProperties.bind(this);
        this.props.onCreateSiuf.bind(this);
    }

    public componentDidMount() {

        if (this.props.defaultOwnerUpn) {
            this.props.onUpdateNewSiufProperties({
                ...this.props.newSiufModel,
                Owner: this.props.defaultOwnerUpn
            });
        }
    }

    public componentWillUnmount() {
        this.props.onUpdateNewSiufProperties({
            ...INewSiufModel_Default
        });
    }

    public render() {
        return (
            <React.Fragment>
                <Form
                    loading={this.props.submitState.requestState === RequestState.inProgress}
                    error={(this.props.submitState.requestState === RequestState.complete) && (this.props.submitState.errorType === ErrorType.error)}
                    success={(this.props.submitState.requestState === RequestState.complete) && (this.props.submitState.errorType === ErrorType.none)}
                >
                    {
                        (this.props.submitState.requestState === RequestState.complete && this.props.submitState.response)
                        &&
                        <Message success header={
                            <span>
                                SIUF<a href={Navigation.Route_ExistingAuthoringPage(this.props.submitState.response.SiufId)} target=''>
                                    {this.props.submitState.response.SiufId}
                                </a> was successfully created. <a href={Navigation.Route_ExistingAuthoringPage(this.props.submitState.response.SiufId)} target=''>
                                    View SIUF</a>
                            </span>
                        }
                        >
                        </Message>
                    }
                    {
                        (this.props.submitState.requestState === RequestState.complete)
                        &&
                        <Message
                            error
                            header='An error occured during SIUF creation'
                            content={this.props.submitState.errorMessage || "<No error message shown>"}
                        />
                    }
                    {
                        this.props.submitState.requestState !== RequestState.complete
                        &&
                        <React.Fragment>
                            <Form.Group widths='equal'>
                                <Form.Input fluid label='SIUF Name' required
                                    defaultValue={this.props.newSiufModel.NewSiufName}
                                    placeholder='Enter the name of the SIUF'
                                    onChange={e => {
                                        this.props.onUpdateNewSiufProperties({
                                            ...this.props.newSiufModel,
                                            NewSiufName: e.currentTarget.value
                                        });
                                    }}
                                    tabIndex="4" />
                                <Form.Input fluid label='Owner Upn' required
                                    defaultValue={this.props.newSiufModel.Owner || this.props.defaultOwnerUpn}
                                    placeholder='Enter the owner upn (e.g. User@ntdev.microsoft.com)'
                                    onChange={e => {
                                        this.props.onUpdateNewSiufProperties({
                                            ...this.props.newSiufModel,
                                            Owner: e.currentTarget.value
                                        });
                                    }}
                                    tabIndex="5" />
                            </Form.Group>
                            <Header as='h5'>Select how you would like to create this SIUF:</Header>
                            <Form.Radio
                                label='Create new SIUF'
                                name='SiufCreation_CreateNewSIUF'
                                title='Create new SIUF'
                                tabIndex="6"
                                className='ui radio_button_new'
                                checked={this.props.newSiufModel.CreationType === CreationType.CreateNewSiuf}
                                value={CreationType.CreateNewSiuf}
                                onChange={() => this.props.onUpdateNewSiufProperties({
                                    ...this.props.newSiufModel,
                                    CreationType: CreationType.CreateNewSiuf
                                })}
                            />
                            <Form.Radio
                                label='Clone from an existing SIUF'
                                name='SiufCreation_CloneFromAnExistingSIUF'
                                title='Clone from an existing SIUF'
                                tabIndex="7"
                                className='ui radio_button_new'
                                checked={this.props.newSiufModel.CreationType === CreationType.CloneSiuf}
                                value={CreationType.CloneSiuf}
                                onChange={() => this.props.onUpdateNewSiufProperties({
                                    ...this.props.newSiufModel,
                                    CreationType: CreationType.CloneSiuf
                                })}
                            />
                            {
                                (this.props.newSiufModel.CreationType === CreationType.CloneSiuf)
                                &&
                                <Form.Field required
                                    control='input' type='number' min={0}
                                    defaultValue={this.props.newSiufModel.NewSiufName}
                                    tabIndex="8"
                                    placeholder='Enter Id of the SIUF to clone from'
                                    onChange={(e: any) => {
                                        this.props.onUpdateNewSiufProperties({
                                            ...this.props.newSiufModel,
                                            OldSiufId: Number(e.currentTarget.value)
                                        });
                                    }} />
                            }
                            {
                                (this.props.newSiufModel.CreationType === CreationType.CreateNewSiuf)
                                &&
                                <React.Fragment>
                                    <Header as='h5'>Select how you would like to trigger this SIUF:</Header>
                                    <Form.Radio
                                        label='Uri link'
                                        name='SiufTriggerType_UriLink'
                                        title='Uri link'
                                        tabIndex="9"
                                        className='ui radio_button_new'
                                        checked={this.props.newSiufModel.TriggeringType === SiufTriggeringType.URI}
                                        value={SiufTriggeringType.URI}
                                        onChange={() => this.props.onUpdateNewSiufProperties({
                                            ...this.props.newSiufModel,
                                            TriggeringType: SiufTriggeringType.URI
                                        })}
                                    />
                                    <Form.Radio
                                        label='State machine'
                                        name='SiufTriggerType_StateMachine'
                                        title='State machine'
                                        tabIndex="10"
                                        className='ui radio_button_new'
                                        checked={this.props.newSiufModel.TriggeringType === SiufTriggeringType.StateMachine}
                                        value={SiufTriggeringType.StateMachine}
                                        onChange={() => this.props.onUpdateNewSiufProperties({
                                            ...this.props.newSiufModel,
                                            TriggeringType: SiufTriggeringType.StateMachine
                                        })}
                                    />
                                </React.Fragment>
                            }
                            {
                                <Form.Button type='submit'
                                    disabled={!this.props.newSiufModel.NewSiufName || !this.props.newSiufModel.Owner || (this.props.newSiufModel.CreationType === CreationType.CloneSiuf && !this.props.newSiufModel.OldSiufId)}
                                    onClick={() => this.props.onCreateSiuf(this.props.newSiufModel)}
                                    primary>
                                    Create SIUF
                                </Form.Button>
                            }
                        </React.Fragment>
                    }
                </Form>
            </React.Fragment>
        );
    }
}