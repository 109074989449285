import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../configureStore";
import ExistingSiuf from '../../components/authoring/ExistingSiuf';
import * as selectors from '../../selectors';
import * as siufAuthoringActions from '../../actions';
import { SiufAuthoringSectionId, IUpdateSiufModel } from '../../contracts/models';
import { RouteComponentProps } from 'react-router';
import { IConnectedReduxProps } from '../../reducers';
import { ThunkDispatch } from 'redux-thunk';
import Navigation from '../../navigation/navigationActions';

type IPropsFromState = {
};

type IRouteParams = {
    id: string;
};

type AllProps = IPropsFromState & RouteComponentProps<IRouteParams> & IConnectedReduxProps;

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: AllProps) => ({
    siufId: parseInt(ownProps.match.params.id, 10),
    siufModel: selectors.getSiufEntity(state),
    uiState: selectors.getSiuAuthoringUiState(state),
    submitState: selectors.getExistingSiufModelSubmitState(state),
    isDirty: selectors.getExistingSiufDirtyState(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: AllProps) => ({
    fetchSiuf: () => {
        // Make sure we indicate to the app state that this is the context we are working with.
        // Note: We need to obtain the context Id from the route in case the user directly navigate to the context detail page
        const id = parseInt(ownProps.match.params.id, 10);
        if (isNaN(id)) {
            dispatch(Navigation.NavigateTo_NotFoundPage());
        } else {
            dispatch(siufAuthoringActions.getSiufFromServerFromServer(id));
        }
    },
    onSaveSiuf: (siufId: number, updateSiufModel: IUpdateSiufModel) => dispatch(siufAuthoringActions.saveSiufFromServer(siufId, updateSiufModel)),
    onSubmitSiuf: (siufId: number) => dispatch(siufAuthoringActions.submitSiufFromServer(siufId, '')),
    onWithdrawSiuf: (siufId: number, reason: string) => dispatch(siufAuthoringActions.withdrawSiufFromServer(siufId, reason)),
    onTerminateSiuf: (siufId: number, reason: string) => dispatch(siufAuthoringActions.terminateSiufByAuthorFromServer(siufId, reason)),
    onReactivateSiuf: (siufId: number) => dispatch(siufAuthoringActions.reactivateSiufFromServer(siufId, '')),
    onDismissSubmitState: () => dispatch(siufAuthoringActions.dismissSubmitState()),
    onUpdateSectionVisibility: (sectionId: SiufAuthoringSectionId, isVisible: boolean) => dispatch(siufAuthoringActions.updateUiAuthoringSectionVisibility(sectionId, isVisible))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExistingSiuf);
