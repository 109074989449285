import { IUpdateAnswerModel, IUpdateDeviceFilterModel, IUpdateJustificationModel, IUpdatePayloadModel, IUpdateQuestionModel, IUpdateSiufModel, IUpdateStateModelModel, IUpdateTargetingModel } from "./serverRequestContracts";
import { IAnswerEntity, IDeviceFilterEntity, IJustificationEntity, IPayloadEntity, IQuestionEntity, IReviewSiufEntity, ISiufEntity, IStateModelEntity, ITargetingEntity, SiufQuestionType } from "./serverResponseContracts";

export const StateModelEntityToUpdateStateModelModelMapper = (stateModelEntity: IStateModelEntity): IUpdateStateModelModel => {
    return {
        StateMachineModel: stateModelEntity.StateMachineModel,
        MaxDurationMs: stateModelEntity.MaxDurationMs
    };
};

export const AnswerEntityToUpdateAnswerModelMapper = (answerEntity: IAnswerEntity): IUpdateAnswerModel => {
    return {
        AnswerText: answerEntity.AnswerText?.trim(),
        AnswerIndex: answerEntity.AnswerIndex,
        FollowUpQuestionText: answerEntity.FollowUpQuestion ? answerEntity.FollowUpQuestion.QuestionText : ""
    };
};

export const QuestionEntityToUpdateQuestionModelMapper = (questionEntity: IQuestionEntity): IUpdateQuestionModel => {
    return {
        QuestionText: questionEntity.QuestionText?.trim(),
        QuestionStructure: questionEntity.QuestionStructure,
        QuestionType: questionEntity.QuestionType,
        QuestionIndex: questionEntity.QuestionIndex,
        Answers: questionEntity.Answers.map(a => AnswerEntityToUpdateAnswerModelMapper(a)),
        ScaleItems: questionEntity.ScaleItems,
        IsNpsQuestion: GetIsNpsQuestionFromQuestionEntity(questionEntity)
    };
};

export const GetIsNpsQuestionFromQuestionEntity = (questionEntity: IQuestionEntity): boolean => {
    if(questionEntity.QuestionType === SiufQuestionType.FivePoint || 
        (questionEntity.QuestionType === SiufQuestionType.Likert && questionEntity.ScaleItems?.length === 1 && questionEntity.Answers?.length === 5)) {
        return questionEntity.IsNpsQuestion;
    }
    return false;
}

export const JustificationEntityToUpdateJustificationModelMapper = (justificationEntity: IJustificationEntity): IUpdateJustificationModel => {
    return {
        QuestionType: justificationEntity.QuestionType,
        Answer: justificationEntity.Answer
    };
};

export const DeviceFilterEntityToUpdateDeviceFilterModelMapper = (deviceFilterEntity: IDeviceFilterEntity): IUpdateDeviceFilterModel => {
    return {
        FilterName: deviceFilterEntity.FilterName,
        Hive: deviceFilterEntity.Hive,
        KeyName: deviceFilterEntity.KeyName,
        ValueName: deviceFilterEntity.ValueName,
        ValueType: deviceFilterEntity.ValueType,
        Value: deviceFilterEntity.Value,
        Operation: deviceFilterEntity.Operation
    };
};

export const TargetingEntityToUpdateTargetingModelMapper = (targetingEntity: ITargetingEntity): IUpdateTargetingModel => {
    return {
        MinBuild: targetingEntity.MinBuild,
        MaxBuild: targetingEntity.MaxBuild,
        DeviceType: targetingEntity.DeviceType,
        TargetType: targetingEntity.TargetType,
        Rings: targetingEntity.Rings,
        RingChannels: targetingEntity.RingChannels,
        CustomGroupIds: targetingEntity.CustomGroupIds,
        DeviceIdUri: targetingEntity.DeviceIdUri,
        AudienceName: targetingEntity.AudienceName,
        DeviceFilters: targetingEntity.DeviceFilters.map(d => DeviceFilterEntityToUpdateDeviceFilterModelMapper(d))
    };
};

export const PayloadEntityToUpdatePayloadModelMapper = (payloadEntity: IPayloadEntity): IUpdatePayloadModel => {
    return {
        TriggeringType: payloadEntity.TriggeringType,
        Title: payloadEntity.Title?.trim(),
        UseCaseDescription: payloadEntity.UseCaseDescription?.trim(),
        CREQuestionId: payloadEntity.CREQuestionId,
        AskUserForEmail: payloadEntity.AskUserForEmail,
        NoAutoDeactivate: payloadEntity.NoAutoDeactivate,
        DoNotAskSiufAgainIfSeenPreviousVersion: payloadEntity.DoNotAskSiufAgainIfSeenPreviousVersion,
        SurveyRequesterName: payloadEntity.SurveyRequesterName,
        HeroImageUrl: payloadEntity.HeroImageUrl,
        ProfileImageUrl: payloadEntity.ProfileImageUrl,
        IgnoreTitleOnToast: payloadEntity.IgnoreTitleOnToast,
        CREExceptions: payloadEntity.CREExceptions.map(c => c.CREExceptionId),
        Pages: payloadEntity.Pages.map((page, index) => {
            return {
                pageIndex: page.PageIndex,
                Questions: page.Questions.map(q => QuestionEntityToUpdateQuestionModelMapper(q))
            }
        }),
        ToastText: payloadEntity.ToastText?.trim(),
        CoolOffPeriodInDays: payloadEntity.CoolOffPeriodInDays
    };
};

export const SiufEntityToUpdateSiufModelMapper = (siufEntity: ISiufEntity): IUpdateSiufModel => {
    return {
        Owner: siufEntity.Owner,
        SiufName: siufEntity.SiufName,
        LifeSpanInDays: siufEntity.LifeSpanInDays,
        Justifications: siufEntity.Justifications.map(j => JustificationEntityToUpdateJustificationModelMapper(j)),
        Comments: "",
        UpdatePayloadModel: PayloadEntityToUpdatePayloadModelMapper(siufEntity.Payload),
        UpdateTargetingModel: siufEntity.Targeting ? TargetingEntityToUpdateTargetingModelMapper(siufEntity.Targeting) : null,
        UpdateStateModelModel: siufEntity.StateModel ? StateModelEntityToUpdateStateModelModelMapper(siufEntity.StateModel) : null
    };
};

export const SiufEntityToReviewSiufEntityMapper = (siufEntity: ISiufEntity): IReviewSiufEntity => {
    return {
        SiufId: siufEntity.SiufId,
        SiufName: siufEntity.SiufName,
        Owner: siufEntity.Owner,
        ScenarioId: siufEntity.ScenarioId,
        Version: siufEntity.Version,
        Iteration: siufEntity.Iteration,
        State: siufEntity.State,
        LifeSpanInDays: siufEntity.LifeSpanInDays,
        LastUpdateUser: siufEntity.LastUpdateUser,
        LastUpdatedTime: siufEntity.LastUpdatedTime,
        AuditAction: siufEntity.AuditAction,
        AuditMessage: siufEntity.AuditMessage,
        Justifications: siufEntity.Justifications,
        Payload: siufEntity.Payload,
        Targeting: siufEntity.Targeting,
    };
};