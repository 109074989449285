import { action } from "typesafe-actions";
import { PropertiesActionTypes } from ".";
import { SiufAdvancedProperty } from "../../../contracts/models";

export const updateAdvancedProperties = (property: SiufAdvancedProperty, propertyValue: boolean) => action(
    PropertiesActionTypes.UPDATE_ADVANCEDPROPERTY,
    {
        property,
        propertyValue
    }
);

export const updatePreviousQuestionId = (questionId: string) => action(
    PropertiesActionTypes.UPDATE_PREVIOUS_QUESTION_ID,
    {
        questionId
    }
);

export const updateCoolOffPeriod = (coolOffPeriodInDays?: number) => action(
    PropertiesActionTypes.UPDATE_COOL_OFF_PERIOD,
    {
        coolOffPeriodInDays
    }
);