import { StateMachineModel, StateMachineModel_Default } from "../states/stateMachineStates";
import { SiufTriggeringType } from "./serverRequestContracts";
import { DEFAULT_MAX_DURATION_IN_MS } from "./constants";

//
// Source: SIUF Authoring API
// Please do not modify unless the server API contract changes
//

export enum SiufFocus {
    None = 0,
    Difficulty,
    Satisfaction,
    NetPromoter,
    Usefulness,
    Duration,
    Efficiency,
    Frequency,
    Accuracy,
    Aesthetics,
    Custom
}

export enum SiufDispatchType {
    Default = 0,
    Compat,
    Shell,
    DirectActivate,
    URI,
    Cortana,
    UIF,
    InteractiveToastRatingsDropDownSingle,
    InteractiveToastRatingsDropDownDouble,
    InteractiveToastRatingsButtonsDouble,
    InteractiveToastRatingsEmojiButtonsDouble,
    MultiQuestion,
    QuestQuickPolls
}

export enum SiufQuestionType {
    FivePoint = 0,
    ThreePoint = 1,
    YesNo = 2,
    CheckBox = 3,
    Text = 4,
    Likert = 5
}

export enum LikertResponseType {
    Numeric = 1,
    Alphanumeric = 2
}

export enum LikertResponseCount {
    Three = 3,
    Five = 5,
    Ten = 10,
    Custom = 0
}

export enum SiufQuestionStructure {
    SingleQuestion = 0,
    MultiQuestion = 1,
    FollowUpQuestion = 2
}

export enum SiufCREException {
    Frequency = 0,
    Reask,
    PostLogonQuietPeriod,
    ReaskAfterUpdate,
    MissingLocFile,
    RequiresInternet,
    ManualTriggerPassThrough,
    UseFeedbackHubToToast = 8,
    ReaskAfterCoolOff
}

export enum SiufState {
    Draft = 0,
    Submitted = 1,
    Approved = 2,
    Published = 3,
    Terminated = 4
}

export enum SiufAuditAction {
    Created = 0,
    Updated,
    Submitted,
    Approved,
    Published,
    Rejected,
    Terminated,
    Canceled,
    Reactivated
}

export enum SiufRing {
    Canary = 0,
    OSG,
    MSIT,
    WIF,
    WIS,
    RP,
    NOT_SET,
    Retail
}

export enum SiufRingChannel {
    Canary = 0,
    OSG,
    MSIT,
    WIF,
    WIS,
    RP,
    NOT_SET,
    Retail,
    Dev,
    Beta,
    Feature_Preview,
    Release_Preview,
    Dev_Internal,
    Dev_External,
    Beta_Internal,
    Beta_External,
    Feature_Preview_Internal,
    Feature_Preview_External,
    Release_Preview_Internal,
    Release_Preview_External
}

export enum SiufDeviceType {
    Desktop = 0,
    Xbox,
    Core,
    TeamOS,
    Mobile,
    Holographic,
    Andromeda
}

export enum SiufTargetingMethod {
    OneSettingsFilters = 0,
    CustomGroups
}

export enum SiufTargetType {
    Rings = 0,
    AudienceId,
    CustomGroups,
    DeviceIdUri,
    RetailRecommendedSampling,
    AllRingsAndAllDevices,
    RingChannels
}

export enum SiufJustificationQuestionType {
    RetailJustification = 0,
    BusinessValueJustification,
    RepetitionJustification
}

export enum RegistryHiveType {
    HKEY_CLASSES_ROOT = 0,
    HKEY_CURRENT_CONFIG = 1,
    HKEY_CURRENT_USER = 2,
    HKEY_CURRENT_USER_LOCAL_SETTINGS = 3,
    HKEY_LOCAL_MACHINE = 4,
    HKEY_PERFORMANCE_DATA = 5,
    HKEY_PERFORMANCE_NLSTEXT = 6,
    HKEY_PERFORMANCE_TEXT = 7,
    HKEY_USERS = 8
}

export enum RegistryValueType {
    REG_DWORD = 0,
    REG_QWORD = 1,
    REG_SZ = 2,
    REG_BINARY = 3,
    REG_NONE = 4,
    REG_EXPAND_SZ = 5,
    REG_MULTI_SZ = 6
}

export enum RegistryOperators {
    equal = 0,
    notequal = 1,
    greaterthan = 2,
    lessthan = 3,
    greaterthanequal = 4,
    lessthanequal = 5,
    like = 6,
    notlike = 7,
    regex = 8,
    notregex = 9,
    exists = 10,
    notexists = 11
}

export enum ErrorLevel {
    Error = 0,
    Warning = 1
}

export enum SiufValidationSource {
    ObjectModelValidator = 0,
    UtcScenarioValidator,
    AuthoringValidator
}

export interface ICREExceptionEntity {
    CREExceptionId: SiufCREException;
    CREExceptionName: string;
}

export interface IStateModelEntity {
    StateMachineModel: StateMachineModel;
    MaxDurationMs: number;
}

export const IStateModelEntity_Default: IStateModelEntity = {
    StateMachineModel: StateMachineModel_Default,
    MaxDurationMs: DEFAULT_MAX_DURATION_IN_MS
};

export interface IDeviceFilterEntity {
    FilterName: string;
    Hive: RegistryHiveType;
    KeyName: string;
    ValueName: string;
    ValueType: RegistryValueType;
    Value: string;
    Operation: RegistryOperators;
}

export const IncludeTestSystemDeviceFilter: IDeviceFilterEntity = {
    FilterName: "Test Systems False",
    Hive: RegistryHiveType.HKEY_LOCAL_MACHINE,
    KeyName: `SOFTWARE\\Microsoft\\SQMClient`,
    ValueName: "IsTest",
    ValueType: RegistryValueType.REG_NONE,
    Value: "",
    Operation: RegistryOperators.notexists
};

export const IDeviceFilterEntity_Default: IDeviceFilterEntity = {
    FilterName: '',
    Hive: RegistryHiveType.HKEY_CURRENT_USER,
    KeyName: '',
    ValueName: '',
    ValueType: RegistryValueType.REG_DWORD,
    Value: '',
    Operation: RegistryOperators.equal
};

export interface IJustificationEntity {
    QuestionType: SiufJustificationQuestionType;
    Question: string;
    Answer: string;
}

export interface ITargetingEntity {
    MinBuild: number;
    MaxBuild?: number;
    DeviceType: SiufDeviceType;
    TargetingMethod: SiufTargetingMethod;
    TargetType: SiufTargetType;
    Rings: SiufRing[];
    RingChannels: SiufRingChannel[];
    CustomGroupIds: string[];
    DeviceIdUri: string;
    AudienceName: string;
    DeviceFilters: IDeviceFilterEntity[];
}

export const ITargeting_Default: ITargetingEntity = {
    MinBuild: 0,
    MaxBuild: 0,
    DeviceType: SiufDeviceType.Desktop,
    TargetingMethod: SiufTargetingMethod.OneSettingsFilters,
    TargetType: SiufTargetType.RingChannels,
    Rings: [],
    RingChannels: [SiufRingChannel.Canary, SiufRingChannel.OSG, SiufRingChannel.MSIT, SiufRingChannel.WIF, SiufRingChannel.WIS, SiufRingChannel.RP, SiufRingChannel.NOT_SET],
    CustomGroupIds: [],
    DeviceIdUri: '',
    AudienceName: '',
    DeviceFilters: [],
};

export interface IAnswerEntity {
    AnswerText: string;
    AnswerIndex: number;
    FollowUpQuestion: IQuestionEntity;
}

export interface IScaleItemEntity {
    ScaleItemIndex: number;
    ScaleItemText?: string;
}

export const IAnswerEntityDefault: IAnswerEntity = {
    AnswerText: "",
    AnswerIndex: 0,
    FollowUpQuestion: null
};

export const IScaleItemEntityDefault: IScaleItemEntity = {
    ScaleItemText: "",
    ScaleItemIndex: 0
};

export interface IQuestionEntity {
    QuestionText: string;
    QuestionStructure: SiufQuestionStructure;
    QuestionType: SiufQuestionType;
    QuestionIndex: number;
    Answers: IAnswerEntity[];
    LikertResponseType?: number;
    LikertResponseCount?: number;
    ScaleItems?: IScaleItemEntity[];
    IsNpsQuestion: boolean;
}

export interface IPageEntity {
    PageIndex: number;
    Questions: IQuestionEntity[];
}

export const FivePointQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.FivePoint,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: [
        {
            AnswerText: 'Too hard',
            AnswerIndex: 0,
            FollowUpQuestion: null
        },
        {
            AnswerText: '',
            AnswerIndex: 1,
            FollowUpQuestion: null
        },
        {
            AnswerText: '',
            AnswerIndex: 2,
            FollowUpQuestion: null
        },
        {
            AnswerText: '',
            AnswerIndex: 3,
            FollowUpQuestion: null
        },
        {
            AnswerText: 'Not hard',
            AnswerIndex: 4,
            FollowUpQuestion: null
        },

    ]
};

export const PageEntityDefault: IPageEntity = {
    PageIndex: 0,
    Questions: [FivePointQuestionEntityDefault]
}

export const ThreePointQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.ThreePoint,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: [
        {
            AnswerText: '',
            AnswerIndex: 0,
            FollowUpQuestion: null
        },
        {
            AnswerText: '',
            AnswerIndex: 1,
            FollowUpQuestion: null
        },
        {
            AnswerText: '',
            AnswerIndex: 2,
            FollowUpQuestion: null
        }
    ]
};

export const TextQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.Text,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: []
};

export const YesNoQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.YesNo,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: [
        {
            AnswerText: 'Yes',
            AnswerIndex: 0,
            FollowUpQuestion: null
        },
        {
            AnswerText: 'No',
            AnswerIndex: 1,
            FollowUpQuestion: null
        }
    ]
};

export const CheckBoxQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.CheckBox,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: [
        {
            AnswerText: 'Other',
            AnswerIndex: 0,
            FollowUpQuestion: null
        }
    ]
};

export const LikertQuestionEntityDefault: IQuestionEntity = {
    QuestionText: "",
    QuestionStructure: SiufQuestionStructure.SingleQuestion,
    QuestionType: SiufQuestionType.Likert,
    LikertResponseCount: LikertResponseCount.Custom,
    LikertResponseType: LikertResponseType.Alphanumeric,
    QuestionIndex: 0,
    IsNpsQuestion: false,
    Answers: [
        {
            AnswerText: '',
            AnswerIndex: 0,
            FollowUpQuestion: null
        }
    ],
    ScaleItems: []
};

export interface IPayloadEntity {
    SiufObjectModelVersion?: number;
    SiufObjectModelMinorVersion?: number;
    SiufObjectModelPatchNumber?: number;
    DispatchType: SiufDispatchType;
    Title: string;
    UseCaseDescription: string;
    TriggeringType: SiufTriggeringType;
    CREQuestionId?: string;
    AskUserForEmail: boolean;
    NoAutoDeactivate: boolean;
    DoNotAskSiufAgainIfSeenPreviousVersion: boolean;
    SurveyRequesterName: string;
    HeroImageUrl: string;
    ProfileImageUrl: string;
    IgnoreTitleOnToast: boolean;
    CREExceptions: ICREExceptionEntity[];
    Questions: IQuestionEntity[];
    ToastText: string;
    Pages: IPageEntity[];
    CoolOffPeriodInDays?: number;
    ActiveEditPage: string | number;
    ActivePreviewPage: string | number;
}

export const IPayload_Default: IPayloadEntity = {
    SiufObjectModelVersion: 0,
    SiufObjectModelMinorVersion: 0,
    SiufObjectModelPatchNumber: 0,
    DispatchType: SiufDispatchType.Default,
    Title: '',
    UseCaseDescription: '',
    TriggeringType: SiufTriggeringType.URI,
    CREQuestionId: '',
    AskUserForEmail: false,
    NoAutoDeactivate: false,
    DoNotAskSiufAgainIfSeenPreviousVersion: false,
    SurveyRequesterName: '',
    HeroImageUrl: '',
    ProfileImageUrl: '',
    IgnoreTitleOnToast: false,
    CREExceptions: [],
    Questions: [
        FivePointQuestionEntityDefault
    ],
    ToastText: "",
    Pages: [{
        PageIndex: 0,
        Questions: [ FivePointQuestionEntityDefault ]
    }],
    CoolOffPeriodInDays: null,
    ActiveEditPage: 0,
    ActivePreviewPage: 0
};

export interface ISiufEntity {
    SiufId: number;
    SiufName: string;
    Owner: string;
    ScenarioId: string;
    Version: number;
    Iteration: number;
    State: SiufState;
    LifeSpanInDays: number;
    LastUpdateUser: string;
    LastUpdatedTime: string;
    AuditAction: SiufAuditAction;
    AuditMessage: string;
    Justifications: IJustificationEntity[];
    Payload: IPayloadEntity;
    StateModel: IStateModelEntity;
    Targeting: ITargetingEntity;
}

export const ISiufEntity_Default: ISiufEntity = {
    SiufId: 0,
    SiufName: '',
    Owner: '',
    ScenarioId: '',
    Version: 0,
    Iteration: 0,
    State: SiufState.Draft,
    LifeSpanInDays: 0,
    LastUpdateUser: '',
    LastUpdatedTime: "",
    AuditAction: SiufAuditAction.Created,
    AuditMessage: '',
    Justifications: [],
    Payload: IPayload_Default,
    StateModel: IStateModelEntity_Default,
    Targeting: ITargeting_Default
};

export interface IReviewSiufEntity {
    SiufId: number;
    SiufName: string;
    Owner: string;
    ScenarioId: string;
    Version: number;
    Iteration: number;
    State: SiufState;
    LifeSpanInDays: number;
    LastUpdateUser: string;
    LastUpdatedTime: string;
    AuditAction: SiufAuditAction;
    AuditMessage: string;
    Justifications: IJustificationEntity[];
    Payload: IPayloadEntity;
    Targeting: ITargetingEntity;
}

export const IReviewSiufEntity_Default: IReviewSiufEntity = {
    SiufId: 0,
    SiufName: '',
    Owner: '',
    ScenarioId: '',
    Version: 0,
    Iteration: 0,
    State: SiufState.Draft,
    LifeSpanInDays: 0,
    LastUpdateUser: '',
    LastUpdatedTime: "",
    AuditAction: SiufAuditAction.Created,
    AuditMessage: '',
    Justifications: [],
    Payload: IPayload_Default,
    Targeting: ITargeting_Default
};

export interface IValidationError {
    Level: ErrorLevel;
    Message: string;
    ValidationSource: SiufValidationSource;
}

export interface ISiufValidationResult {
    Errors: IValidationError[];
    IsValid: boolean;
    HasErrors: boolean;
    HasWarnings: boolean;
}

export const ISiufValidationResult_Default: ISiufValidationResult = {
    Errors: [],
    IsValid: true,
    HasErrors: false,
    HasWarnings: false
};
export interface ISiufAuthoringApiResponseError {
    ResponseStatus: number;
    ErrorMessage: string;
    ValidationErrors: string[];
}

export interface ISiufAuthoringApiResponse<T> {
    Data: T;
    ResponseStatus: number;
    ErrorMessage: string;
    ValidationErrors: string[];
}

// This mimics the Axios response type to decouple the API from taking a hard dependency on Axios library. See AxiosResponse type for details
export interface IAxiosErrorResponseData {
    data: ISiufAuthoringApiResponseError;
    status: number;
    statusText: string;
    headers: any;
    request?: any;
}

// This mimics the Axios response type to decouple the API from taking a hard dependency on Axios library. See AxiosError type for details
export interface IAxiosErrorResponse {
    code?: string;
    request?: any;
    response: IAxiosErrorResponseData;
}