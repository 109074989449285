import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as siufAuthoringActions from '../../../actions';
import Approval from '../../../components/reviewing/approval/Approval';
import { IApplicationRootReducerState } from "../../../configureStore";
import * as selectors from '../../../selectors';
import { SiufSortProperty } from '../../../contracts/models';

type Props = {

};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufCollection: selectors.getSortedReviewSiufCollectionAppState(state),
    activePreviewPage: selectors.getSiufActivePreviewPage(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    onFetchSiufs: () => dispatch(siufAuthoringActions.getSiufReviewsFromServer()),
    onApproveSiuf: (siufId: number, reason: string) => dispatch(siufAuthoringActions.approveSiufFromServer(siufId, reason)),
    onRejectSiuf: (siufId: number, reason: string) => dispatch(siufAuthoringActions.rejectSiufFromServer(siufId, reason)),
    onTerminateSiuf: (siufId: number, reason: string) => dispatch(siufAuthoringActions.terminateSiufFromServer(siufId, reason)),
    onUpdateSortProperty: (property: SiufSortProperty) => dispatch(siufAuthoringActions.updateSiufSortProperty(property)),
    onChangePreviewPage: (pageIndex: string | number) => dispatch(siufAuthoringActions.changePreviewPage(pageIndex))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Approval);