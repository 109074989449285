//
// Configurations that we retrieve from the server or environment variables
//
import axios from 'axios';
import { IResourceProviderOptions, IAzureAdConfigOptions } from './contracts';

let environmentConfigs: IResourceProviderOptions = null;

const GetCurrentEnvironmentConfigs = async () => {
    try {
        const response = await axios.get<IResourceProviderOptions>(window.location.origin + "/api/home/resourceProviderOptions");
        if (response && response.data) {
            return response.data;
        } else {
            console.error("Failed to load initial resource configurations from the server");
            return null;
        }
    } catch (error) {
        console.error("Failed to load initial resource configurations from the server. Details: " + error);
    }
};

// Setup test config so we don't have to start the backend
// Note: DO NOT INCLUDE ANY SECRETS HERE, any information here must be public only
// Note: this section should match the appsettings.development.json section

const TestResourceProviderOptions = {
    azureAD: {
        clientId: "d59234bb-04f4-40f2-85a0-1db6eb593d8c",
        authority: "https://login.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    } as IAzureAdConfigOptions,
    endpoints: {
        siufApiBaseUrl: "https://siufauthoringwebapippe.azurewebsites.net/api/v1",
        siufApiScope: "08f6a188-67c0-4e23-b789-42bcb60881cd/user_impersonation"
    }
} as IResourceProviderOptions;

const GetTestEnvironmentConfigs = async (delay = 500) => {
    return new Promise<IResourceProviderOptions>((resolve, reject) => {
        setTimeout(() => {
            resolve(TestResourceProviderOptions);
        }, delay);
    });
};

export const InitializeAppEnvironmentConfigs = async (devMode: boolean): Promise<IResourceProviderOptions> => {
    if (!environmentConfigs) {
        if (devMode) {
            // Note: Only use this in local dev mode to quickly testing without loading the config from the backend
            environmentConfigs = await GetTestEnvironmentConfigs();
        } else {
            environmentConfigs = await GetCurrentEnvironmentConfigs();
        }
    }
    return environmentConfigs;
};

export const GetAppEnvironmentConfigs = () => environmentConfigs;