import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';
import { INewSiufModel } from '../../../contracts/models';
import { ThunkDispatch } from 'redux-thunk';
import SiufCreation from '../../../components/authoring/creation/SiufCreation';
import UserProfileService from '../../../userProfile';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    newSiufModel: selectors.getNewSiufModel(state),
    submitState: selectors.getNewSiufModelSubmitState(state),
    defaultOwnerUpn: UserProfileService.GetCurrentUserUpn()
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: Props) => ({
    onUpdateNewSiufProperties: (newSiufModel: INewSiufModel) => dispatch(siufAuthoringActions.updateNewSiufProperties(newSiufModel)),
    onCreateSiuf: (newSiufModel: INewSiufModel) => dispatch(siufAuthoringActions.createSiufFromServer(newSiufModel))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiufCreation);
