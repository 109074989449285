export interface IAzureAdConfigOptions {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
}

export const IAzureAdConfigOptions_Default: IAzureAdConfigOptions = {
    "clientId": "",
    "authority": "",
    "redirectUri": "",
    "postLogoutRedirectUri": ""
};

export interface IEndpointsOptions {
    siufApiBaseUrl: string;
    siufApiScope: string;
}

export const IEndpointsOptions_Default: IEndpointsOptions = {
    "siufApiBaseUrl": "",
    "siufApiScope": ""
};

export interface IResourceProviderOptions {
    azureAD: IAzureAdConfigOptions;
    endpoints: IEndpointsOptions;
}

export const IResourceProviderOptions_Default: IResourceProviderOptions = {
    "azureAD": IAzureAdConfigOptions_Default,
    "endpoints": IEndpointsOptions_Default
};