import { action } from "typesafe-actions";
import { ThunkResult } from "../..";
import SiufAuthoringApi from "../../../api/siufAuthoringApi";
import { IAxiosErrorResponse, ISiufEntity } from "../../../contracts/models";
import { ParseErrorResponse } from "../../../utils/actionUtils";
import { ReviewApprovalActionTypes } from "./types";

export const getSiufReviewsFromServerSent = () => action(
    ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SENT,
    {
    }
);

export const getSiufReviewsFromServerSuccess = (response: ISiufEntity[]) => action(
    ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SUCCESS,
    {
        response
    }
);

export const getSiufReviewsFromServerFail = (error: string) => action(
    ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_FAIL,
    {
        error
    }
);

export const getSiufReviewsFromServer = (): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(getSiufReviewsFromServerSent());
        try {
            // const response = await MockSiufAuthoringApis.getSiufReviews();
            const response = await SiufAuthoringApi.getAllSiufs();
            dispatch(getSiufReviewsFromServerSuccess(response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(getSiufReviewsFromServerFail(
                ParseErrorResponse(e))
            );
        }
    };
};

export const approveSiufFromServerSent = (siufId: number) => action(
    ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_SENT,
    {
        siufId
    }
);

export const approveSiufFromServerSuccess = (siufId: number, response: ISiufEntity) => action(
    ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_SUCCESS,
    {
        siufId,
        response
    }
);

export const approveSiufFromServerFail = (siufId: number, error: string) => action(
    ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_FAIL,
    {
        siufId,
        error
    }
);

export const approveSiufFromServer = (siufId: number, reason: string = ""): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(approveSiufFromServerSent(siufId));
        try {
            // const response = await MockSiufAuthoringApis.getSiufReviews();
            const response = await SiufAuthoringApi.approveSiuf(siufId, reason);
            dispatch(approveSiufFromServerSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(approveSiufFromServerFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const rejectSiufFromServerSent = (siufId: number) => action(
    ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_SENT,
    {
        siufId
    }
);

export const rejectSiufFromServerSuccess = (siufId: number, response: ISiufEntity) => action(
    ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_SUCCESS,
    {
        siufId,
        response
    }
);

export const rejectSiufFromServerFail = (siufId: number, error: string) => action(
    ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_FAIL,
    {
        siufId,
        error
    }
);

export const rejectSiufFromServer = (siufId: number, reason: string): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(rejectSiufFromServerSent(siufId));
        try {
            // const response = await MockSiufAuthoringApis.getSiufReviews();
            const response = await SiufAuthoringApi.rejectSiuf(siufId, reason);
            dispatch(rejectSiufFromServerSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(rejectSiufFromServerFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};